import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ThomasJpeg from '../../assets/thomas.jpeg'

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))

export function Thomas({ size = 'large' }) {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar alt="Thomas Michelsen" src={ThomasJpeg} className={classes[size]} />
                }
                title="Thomas Michelsen"
                subheader="LL§33A skatteyder"
            />
        </Card>
    )
}
