import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Anchor, Divider } from 'antd'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import BlogPostWrapper from '../../components/BlogPost'
import { Thomas } from '../../components/BlogPost/Authors'
import { HomeLink, InternalLink } from '../../components/BlogPost/Texts'
import SignupBelt from '../../components/Landing/SignupBelt'

const { Link: AnchorLink } = Anchor

const useStyles = makeStyles((theme) => ({
    smallText: {
        color: 'gray',
    },
    italic: {
        fontStyle: 'italic',
    },
}))

const LovenLink = (
    <span style={{ whiteSpace: 'nowrap' }}>
        <InternalLink to="/loven"> LL § 33 A</InternalLink>
    </span>
)

export default function ArticleStartStop({ setCookieValue, data }) {
    const classes = useStyles()
    const anchors = (
        <>
            <AnchorLink href="#titel" title="Intro" />
            <AnchorLink href="#before" title="Før" />
            <AnchorLink href="#now" title="Nu" />
            <AnchorLink href="#praxis" title="I praksis" />
            <AnchorLink href="#doc" title="Dokumentation" />
            <AnchorLink href="#iphone" title="iPhone indstillinger" />
            <AnchorLink href="#better" title="Øget compliance" />
        </>
    )

    return (
        <BlogPostWrapper
            title="GeoSnapshot har fået en opgradering"
            setCookieValue={setCookieValue}
            pathName="blog-geosnapshot-2"
            anchors={anchors}
        >
            <div
                style={{
                    margin: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // flexWrap: 'wrap',
                    marginBottom: 100,
                }}
                id="titel"
            >
                <Img
                    title="Skattestyrelsen logo"
                    alt="Skattestyrelsen logo"
                    style={{ marginBottom: 50 }}
                    className="landing-guide-illustration-big"
                    fluid={data.s1.childImageSharp.fluid}
                />
                <div className="landing-guide-header-p">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <Typography variant="h4" id="titel">
                            GeoSnapshot har fået en opgradering!
                        </Typography>
                        <Typography gutterBottom>
                            ll33a.dk introducerer ”GeoSnapshot-dokumentation"
                        </Typography>
                        <Typography gutterBottom>
                            - Nu kan du ikke blot registrere, hvor du er, men også dokumentere det
                            med et auto-genereret pdf-dokument ”GeoSnapshot-dokumentation”.
                        </Typography>
                        <Typography gutterBottom>
                            - Dette dokument indeholder op til 6 forskellige parametre, der optages
                            i en tidsafgrænset GeoSnapshot-session.
                        </Typography>
                        <Typography gutterBottom>
                            - Dokumentet kan når som helst verificeres vha. SHA256-checksum metoden,
                            så enhver med adgang til dokumentet kan tjekke, at dokumentet er intakt
                            og uændret.
                        </Typography>
                        <Typography gutterBottom variant="h5" id="before">
                            GeoSnapshot FØR
                        </Typography>
                        <Typography gutterBottom>
                            GeoSnapshot-funktionens hovedformål har været at gøre det let i realtid
                            at registrere dit opholdssted. Hermed ajourføres ”den specificerede
                            opgørelse” over opholdssteder for de kalenderdage, hvor du har opholdt
                            dig i udlandet og i Danmark
                            <br />
                            <br />
                            Det er således udelukkende den aktuelle dato og lokation, der bliver
                            noteret i compliance-rapportens kolonne “Opholdssteder”.
                            <br />
                            <br />
                            <Typography component="span" fontSize={14}>
                                (”den specificerede opgørelse” = Den opgørelse som Skattestyrelsen
                                anmoder om ved{' '}
                                <InternalLink to="/blog/materiale-indkaldelse">
                                    materialeindkaldelser (se punkt 11)
                                </InternalLink>
                                .)
                            </Typography>
                        </Typography>
                        <Typography gutterBottom variant="h5" id="now">
                            GeoSnapshot NU
                        </Typography>
                        <Typography gutterBottom>
                            GeoSnapshot-funktionen vil fortsætte med at registrere dine
                            opholdssteder nøjagtig som før.
                            <br />
                            <br />
                            Med den nye opgradering får du nu desuden mulighed for at få
                            autogenereret et dokument, der indeholder oplysninger om dit
                            GeoSnapshot. Tilsammen dokumenterer disse oplysninger dit opholdssted.
                            <br />
                            <br />
                            Processen involverer at indsamle data i individuelle parametre inden for
                            et kort tidsrum og præsentere disse data i en sammenhængende kontekst.
                            Vi kalder denne proces for en GeoSnapshot-session.
                            <br />
                            <br />
                            Hver enkelt parameter, der bliver indsamlet, indgår i den samlede
                            kontekst, hvor du personligt kan blive forbundet med et specifikt
                            opholdssted på et bestemt tidspunkt. Disse parametre registreres i
                            realtid og låses fast i den unikke og tidsafgrænsede
                            GeoSnapshot-session, hvilket er med til at sikrer autenciteten af de
                            indsamlede data.
                            <br />
                            <br />
                            GeoSnapshot-sessionen danner fundamentet for det automatisk genererede
                            dokument. Alle detaljer fra sessionen præsenteres og arkiveres i et
                            PDF-dokument, som du nemt kan finde i din oversigt over dokumentation,
                            på kalendersiden under datoen for det pågældende GeoSnapshot. Hvis det
                            er markeret som "Included" (som er standardindstillingen), vil
                            dokumentet blive inkluderet i compliance-rapporten.
                            <br />
                            <br />
                            Dage, hvor der er anvendt GeoSnapshot, vil fortsat være markeret med
                            orange på kalendersiden.
                        </Typography>
                        <Typography gutterBottom variant="h5" id="praxis">
                            GeoSnapshot-session - i praksis
                        </Typography>
                        <Typography gutterBottom>
                            Ved at klikke på GeoSnapshot ikonet (øverst til højre på kalendersiden)
                            åbnes en tidsafgrænset GeoSnapshot-session, og du får nu en række valg.
                            <br />
                            <br />
                            Du kan vælge:
                            <br />
                            <br />-{' '}
                            <Typography component="span" fontWeight="bold">
                                Tilføj billede
                            </Typography>{' '}
                            = Kamerafunktionen åbnes til selfie optagelse
                            <br />
                            Det er kun muligt at inkludere et foto optaget med enhedens kamera i
                            realtid i den tidsafgrænsede GeoSnapshot-session. Foto arkiveres direkte
                            på ll33a.dk's server.
                            <br />
                            <br />-{' '}
                            <Typography component="span" fontWeight="bold">
                                Tilføj SMS verifikation
                            </Typography>{' '}
                            = SMS-funktionen åbnes med præ-udfyldt;
                            <br />
                            -Telefonnummer til ll33a.dk's SMS-gateway
                            <br />
                            - Tekstfelt bestående af unik session-kode
                            <br />
                            Du skal nu aktivt afsende SMS'en ved at klikke “Send” i din SMS-app.
                            (For at SMS-verifikationen kan indgå i din dokumentation, er det
                            vigtigt, at du ikke ændrer i tekstfeltet). Herefter skal du tilbage i
                            ll33a.dk app'en og afslutte sessionen. (Benyt evt. genvejen oppe i
                            venstre hjørne i din Besked app.)
                            <br />
                            <br />-{' '}
                            <Typography component="span" fontWeight="bold">
                                Gem & afslut
                            </Typography>
                            <br />
                            “Den specificerede opgørelse” over opholdssteder ajourføres, og det
                            auto-genererede dokument gemmes.
                            <br />
                            Har du anvendt ”Tilføj billede”, inkluderes din selfie i dokumentet.
                            <br />
                            Har du anvendt ”Tilføj SMS verifikation”, inkluderes verifikationen i
                            dokumentet.
                            <br />
                            <br />-{' '}
                            <Typography component="span" fontWeight="bold">
                                Afslut uden PDF
                            </Typography>{' '}
                            = GeoSnapshot som FØR
                            <br />
                            Den specificerede opgørelse over opholdssteder ajourføres uden
                            dokumentation.
                            <br />
                            <br />
                            En GeoSnapshot-session, inkl. selfie og SMS-verifikation, kan typisk;
                            åbnes, udføres og afsluttes, inden for ca. 20 sekunder.
                        </Typography>
                        <Typography gutterBottom variant="h5" id="doc">
                            GeoSnapshot-dokumentation - hvad omfatter det
                        </Typography>
                        <Typography gutterBottom>
                            GeoSnapshot dokumenterer dit opholdssted ved hjælp af din enheds
                            aktuelle lokation samt en række parametre, der opsnappes og fastlåses i
                            tidsrummet under en GeoSnapshot-session; herunder:
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                - GeoSnapshot-ID
                            </Typography>
                            <br />
                            Ethvert GeoSnapshot tildeles et unikt ID.
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                - Lokation
                            </Typography>
                            <br />
                            Din enheds position bestemmes ud fra en kombination af mastedata, GPS og
                            WIFI. Positionen angives i længde- og breddegrad med en angivet
                            nøjagtighed indenfor 50m. Denne position angives også med stednavn eller
                            adresse for benævnelse af dit aktuelle opholdssted.
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                - Enhed
                            </Typography>{' '}
                            (device)
                            <br />
                            Om din anvendte enhed registreres, IP-adresse, og user agent data,
                            herunder enhedstype, operativsystem, samt webbrowser, inkl. versions
                            numre.
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                - Tidsstempler for datakommunikation
                            </Typography>
                            <br />
                            Tidsstempler registreres for den datakommunikation, som udveksles mellem
                            din enhed og ll33a.dk's databaseservere i en GeoSnapshot-session.
                            <br />
                            Tiden angives i relation til lokaltid i Danmark ved henholdsvis CET og
                            CEST (Central European (Summer) Time).
                            <br />
                            <br />
                            Du kan vælge at tilføje en selfie og/eller en SMS-verifikation.
                            <br />
                            <Typography component="span" fontWeight="bold">
                                - Selfie (valgfri)
                            </Typography>
                            <br />
                            Ved at tilføje en selfie understøttes dokumentationen af din aktuelle
                            lokation med et selvportræt-foto.
                            <br />
                            Din selfie tages med kameraet på din enhed i samme session, som du har
                            anmodet om et GeoSnapshot. Herved optages og låses din selfie sammen med
                            din enheds lokationsdata.
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                - SMS-verifikation (valgfri)
                            </Typography>
                            <br />
                            Ved at sende en SMS, der indeholder en GeoSnapshot-genereret kode, fra
                            din mobiltelefon direkte til ll33a.dk's servere, knyttes dit
                            mobiltelefon-nummer sammen med dit unikke GeoSnapshot.
                            <br />
                            Med SMS-funktionen verificeres dit tilhørsforhold til den benyttede
                            mobiltelefon i samme session, hvori du har anmodet om et GeoSnapshot.
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                Verificeringslink
                            </Typography>
                            <br />
                            For at sikre og objektivt bekræfte integriteten af dokumentet,
                            indeholder GeoSnapshot dokumentationen et link til ll33a.dk's
                            checksum-verification service, der åbner mulighed for verificering af
                            dokumentet vha. SHA256-checksum metoden.
                            <br />
                            Dette er en sikker og anerkendt teknologi, som garanterer, at selv den
                            mindste ændring i dokumentet vil blive opdaget.
                        </Typography>
                        <Typography gutterBottom variant="h5" id="iphone">
                            Indstillinger på iPhone
                        </Typography>
                        <Typography gutterBottom>
                            <Typography component="span" fontWeight="bold">
                                Lokation:
                            </Typography>
                            <br />
                            Du giver GeoSnapshot adgang til din lokation på iPhone ved at tilvælge
                            begge nedenstående konfigurationsindstillinger:
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                Indstillinger{'>'}Anonymitet & Sikkerhed{'>'}Lokationstjenester{'>'}
                                (slået til)+(rul ned til){'>'}Safari-websteder{'>'}vælg:
                                “Ved-brug-af-appen”
                            </Typography>
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                Indstillinger{'>'}Safari{'>'}Lokalitet{'>'}vælg: ”Tillad"
                            </Typography>
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                Kamera:
                            </Typography>
                            <br />
                            Du giver GeoSnapshot adgang til dit kamera på iPhone ved at vælge:
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                Indstillinger{'>'}Safari{'>'}Kamera{'>'}vælg:”Spørg”
                            </Typography>
                            <br />
                            (eller vælg evt. “Altid”, hvis man vil undgå det ekstra klik:
                            “Accepter”, når kameraet skal åbne til selfie)
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                SMS:
                            </Typography>
                            <br />
                            Du giver GeoSnapshot adgang til din Besked app ved at vælge:
                            <br />
                            <br />
                            <Typography component="span" fontWeight="bold">
                                Indstillinger{'>'}Beskeder{'>'}(rul ned til)Send-Som-SMS (slået til)
                            </Typography>
                        </Typography>
                        <Typography gutterBottom variant="h5" id="better">
                            Øget compliance evne med ll33a.dk
                        </Typography>
                        <Typography gutterBottom>
                            Hvorfor denne ændring af GeoSnapshot?
                            <br />
                            Mange af ll33a.dk's brugere har ytret ønske om nem adgang til mere
                            præcis dokumentation for deres opholdssteder i udlandet. Derfor har vi
                            tilføjet denne nye funktion.
                            <br />
                            Den gør det nemt, hurtigt og transparent at vise præcist, hvor du var.
                            <br />
                            Med GeoSnapshot-dokumentation er det nu muligt at komplimentere andre
                            typer af objektiv dokumentation du indsamler, og dermed danne et mere
                            komplet billede af dine opholdssteder i udlandet, som Skattestyrelsen så
                            hårdt efterspørger ved{' '}
                            <InternalLink to="/blog/materiale-indkaldelse">
                                materialeindkaldelsen
                            </InternalLink>
                            .
                            <br />
                            <br />
                            “GeoSnapshot-sessionen” er blot det senest udviklede, blandt mange andre
                            “redskaber”, du finder i “compliance-værktøjskassen”; <HomeLink />.
                            <br />
                            Redskaber der har til formål at skabe værdi for udstationerede.
                            <br />
                            <br />
                            Har du også et ønske om, eller ideer til “redskaber”, der kan øge din
                            evne til at efterleve og dokumentere forhold omkring ligningsloven §33A,
                            så kontakt os gerne på info@ll33a.dk.
                        </Typography>
                    </Box>
                </div>
            </div>
            <SignupBelt includeLandingRef />
            <p className={classes.smallText}>Sidst opdateret: 31. oktober 2023.</p>
            <Divider />
            <p className={classes.smallText}>Forfatter:</p>
            <Thomas />
        </BlogPostWrapper>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "geosnapshot_feature_illustration.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
