import { Link } from 'gatsby'
import React from 'react'
import './style.less'

export function InternalLink({
    children,
    to,
    style,
}: {
    children
    to
    style?: React.CSSProperties
}) {
    return (
        <Link style={style} className="articel-link" to={to}>
            {children}
        </Link>
    )
}

export function HomeLink() {
    return <InternalLink to="/">ll33a.dk</InternalLink>
}

export function ExternalLink({ children, to }) {
    return (
        <a target="_blank" rel="noreferrer" className="articel-link" href={to}>
            {children}
        </a>
    )
}
