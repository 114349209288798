import React from 'react'
import Container from '../Landing/Container'
import { Anchor } from 'antd'
import Divider from '@mui/material/Divider'
import { isMobile } from 'react-device-detect'
import SEO from '../seo'
import './style.less'
import { LinkedInRef } from '../Logos'

export default function BlogPostWrapper({ title, pathName, children, setCookieValue, anchors }) {
    const affixContainerStyle: React.CSSProperties = isMobile
        ? { display: 'flex', flexDirection: 'column' }
        : { display: 'grid', gridTemplateColumns: '1fr 6fr 1fr', marginLeft: 10, marginRight: 10 }

    return (
        <Container setCookieValue={setCookieValue} pathName={pathName}>
            <SEO article title={title} />
            <span style={affixContainerStyle}>
                {!isMobile && (
                    <Anchor offsetTop={100} targetOffset={156}>
                        {anchors}
                    </Anchor>
                )}
                <div className="article-text">
                    <div className="blogpost-breadcrumb">
                        <br />
                        <Divider />
                        <h1 style={{ overflow: 'hidden' }}>Artikel - {title}</h1>
                    </div>
                    {children}
                </div>
                {!isMobile && (
                    <div>
                        <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '5px' }}>
                            <p>Følg os på:</p>
                        </div>
                        <LinkedInRef />
                    </div>
                )}
            </span>
        </Container>
    )
}
